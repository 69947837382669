@import 'fonts';
@import 'colors';

footer {
    padding: 0.5em;
    nav {
        a {
            @include footer-nav-font;
            text-decoration: none;
            margin-right: 1.5em;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
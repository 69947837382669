.image-galery {
    overflow: auto; /*Dirty, ugly, hack. But should work.*/
}
.image-galery ul {
    list-style-type: none;
    padding: 0;
    display: block;
}

.image_container {
    display: block;
    margin-left: 0;
    margin-right: 1em;
    float: left;
}

main img {
    max-height: 200px;
    max-width: 100%;
}
@import 'mixins';

.cover-image {
    @include floating-box;
    max-width: 100%;
    box-sizing: border-box;
}

img {
    overflow: hidden;
    object-fit: cover;
}
/*Base colors*/
$bright_green: #57a385;
$dark_green: #2B3A41;

/*Derived Colors*/
$dark_background: $dark_green;
$nav_text_hover: $dark_green;
$text_color: #2a3c3e;
$button_hover: $bright_green;
$nav_text_color: white;
$box_color: white;
@import 'colors';

@mixin header-nav-font {
    font: 12px/1 'Lucida Grande', 'Lucida Sans Unicode', Verdana, sans-serif;
    color: $nav_text_color;
    text-transform: uppercase;
    font-size: large;
    font-weight: 700;
}

@mixin footer-nav-font {
    font: 12px/1 'Lucida Grande', 'Lucida Sans Unicode', Verdana, sans-serif;
    color: $nav_text_color;
}

@mixin document-nav-font {
    font-family: 'Graduate', cursive;
    text-transform: uppercase;
    color: $nav_text_color;
}

@mixin heading-font {
    text-transform: capitalize;
    font-family: 'Graduate', cursive;
    color: $text_color;
    line-height: 1em;
}

@mixin text-font {
    font-family: Arial,sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: $text_color;
}
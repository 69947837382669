@import 'desktop_grid';

/* Mobile layout */
@media only screen and (max-width: 960px) {
    @import 'mobile_grid';
}

body > * {
    margin-top: 1em;
    margin-bottom: 1em;
}

/* Assign components to grid cells */
.site-header {
    grid-area: header;
}

.cover-image {
    grid-area: cover-image;
    justify-self: center;
}

main {
    grid-area: body;
}

footer {
    grid-area: footer;
}
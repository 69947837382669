body {
    display: grid;
    grid-template-columns: 1fr 960px 1fr;
    grid-template-rows: auto auto auto 1fr auto;
    grid-template-areas: 
        ". header ."
        ". cover-image ."
        ". body ."
        ". . ."
        ". footer ."
    ;
    margin: 0;
    padding: 0;
    min-height: 100vh;
}
@import 'colors';
@import 'fonts';

.doc-nav {
    a {
        display: inline-block;
        border-radius: 0.5em;
        margin: 0.2em;
        padding: 0.7em;
        text-decoration: none;
        background: $dark_background;
        @include document-nav-font;

        &:hover, &.active.hover {
            background: $bright_green;
        }
        &.active {
            background: $button_hover;
        }
    }
}
/* Content start*/
@import 'grid';
@import 'colors';
@import 'mixins';
@import 'images';
@import 'header_format';
@import 'footer_format';
@import 'document_navigation';
@import 'image_galery';
@import 'tables';

body::before {
    content: "";
    position: fixed;
    display: block;
    z-index: -1;
    width: 110%;
    height: 110%;
    left: -5%;
    top: -5%;
    background: url(/res/images/background-comp.jpg) no-repeat; //TODO: Conditional URL: uncompressed in test, compressed in production
    background-size: cover;
    filter: blur(5px);
}

body {
    background: rgba(black, $alpha: 0.4);
    overflow-y: scroll;
}

header, footer {
    @include floating-box;
    background: $bright_green;
}

h1, h2, h3, h4 {
    @include heading-font;
}

main {
    @include floating-box;
    @include text-font;
}

/** Fonts from Google Fonts. Local copy for GDPR reasons
Refer to: https://fonts.google.com/specimen/Graduate/about?query=Graduate
Original Author: Eduardo Tunni
**/
@font-face {
    font-family: 'Graduate';
    font-style: normal;
    font-weight: 400;
    src: url(/res/style/graduate-font.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@import 'fonts';
@import 'colors';

.site-header {
    display: grid;
    grid-template-areas:
        "logo navigation";
    padding: 0.5em;
    .logo {
        grid-area: logo;
        align-self: center;
        display: block;
        max-width: 218px;
        img {
            max-width: 100%;
        }
    }
    nav {
        grid-area: navigation;
        align-self: center;
        justify-self: right;
        min-width: 200px;
        ul {
            display: inline-block;
            list-style-type: none;
            padding-left: 0;
        }
        li {
            display: inline-block;
            margin-left: 1em;
            @media only screen and (max-width: 960px) {
                display: block;
                float: right;
                clear: both;
                margin: 0.2em;
            }
        }
        a {
            @include header-nav-font;
            text-decoration: none;
            &.active {
                text-decoration: underline;
            }
            &:hover {
                color: $nav_text_hover;
            }
        }
    }
}